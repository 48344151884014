import React, { Component } from 'react';
import Title from '../Title/Title';
import Spinner from '../Spinner/Spinner';
import SingleSignOn from '../SingleSignOn/SingleSignOn';
import { RedirectService } from '../../core/services/redirectService';
import './LogoutPage.scss';
import { deleteAuthCookies } from '../../utilities/cookies';

interface Props {
  redirectService: typeof RedirectService
  SingleSignOnComponent: typeof SingleSignOn
}

interface State {
  ready: boolean
}

class LogoutPage extends Component<Props, State> {
  static defaultProps

  constructor(props) {
    super(props);
    this.state = {
      ready: false
    };
  }

  componentDidMount() {
    (async () => {
      await deleteAuthCookies();
      this.setState({ ready: true });
    })();
  }

  render() {
    return (
      <>
        <Title name="Logging Out..." textPosition="auth-title--center" />
        <hr className="logout__divider" />

        <Spinner />
        <p className="logout__message">
          Please wait...
          <br />
          You're being logged out and you'll be redirected back to the website in a moment.
        </p>

        {this.state.ready && (
          // @ts-expect-error FIXME: SSO & RedirectService types
          <this.props.SingleSignOnComponent action="logout" redirect_url={this.props.redirectService.resolveRedirectUrlForLogout()} />
        )}
      </>
    );
  }
}

LogoutPage.defaultProps = {
  redirectService: RedirectService.Instance(),
  SingleSignOnComponent: SingleSignOn
};

export default LogoutPage;
