import React, { Component } from 'react';
import './AuthAlert.scss';

interface AuthAlertProps {
  message: string
  link?: React.JSX.Element | false | null
  mainDocument: Document
}

class AuthAlert extends Component<AuthAlertProps> {
  public static defaultProps = {
    mainDocument: window.document
  }

  componentDidMount() {
    const error = this.props.mainDocument.querySelector('[data-input-error]');

    if (!error) {return;}

    const previousSibling = error.previousElementSibling;

    if (!previousSibling) {return;}

    const input = previousSibling.querySelector('input');

    if (!input) {return;}

    input.focus();
  }

  render(): JSX.Element {
    return (
      <div className="input--error" data-input-error>
        {this.props.message} {this.props.link}
      </div>
    );
  }
}

export default AuthAlert;
