/* eslint-disable max-len */
import React from 'react';

export const Icons = {
  USCCALogo: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 220 230"
      width="100px"
      height="109px"
      focusable="false"
      role="img"
      aria-hidden="true"
      className="d-block mx-auto"
    >
      <g fill="#fff">
        <path d="M153.9 209.2c2.3 0 4.1-1.8 4.1-4.1s-1.8-4.1-4.1-4.1c-2.2 0-4.1 1.8-4.1 4.1s1.8 4.1 4.1 4.1zm0-7.5c1.9 0 3.4 1.5 3.4 3.4s-1.5 3.4-3.4 3.4-3.4-1.5-3.4-3.4 1.5-3.4 3.4-3.4z"></path>
        <path d="M152.9 205.6h.7l1.2 1.8h.9l-1.2-1.9c.3 0 1.2-.3 1.2-1.4 0-.9-.8-1.4-1.6-1.4h-1.9v4.6h.7v-1.7zm0-2.2h1.2c.4 0 .8.3.8.8s-.4.8-.8.8h-1.2v-1.6z"></path>
      </g>
      <g fill="#fff">
        <path d="M78 41zM160.5 153.1H180c28.1-54.3 23.1-104.3 23.1-104.3-45.4-11.1-79.5-37.6-91-48.8v22.7c9.5 7.6 35.8 27.8 74.4 39.5.1 0 1.1 43.8-26 90.9zM201.2 161.5c-.7-1.2-1.5-2.2-2.4-3.1-.9-.8-2.1-1.2-3.6-1.2s-2.7.4-3.7 1.3-1.8 1.9-2.4 3l-20.8 34.7h12.4l14.3-25.5 3.8 7.1h-4.2l-5.9 10.1h15.5c1.9 3.6 4.4 8.2 4.4 8.2h11.5l-18.9-34.6z"></path>
        <path d="M150.9 153.1c27-46.6 27.9-86.7 27.9-86.7-11.4 53.7-42.4 86.6-42.5 86.7h14.6zM148 186.3c-1.3 0-2.4-.2-3.5-.7-1.1-.4-2-1.1-2.8-1.8-.8-.8-1.4-1.8-1.9-2.9-.4-1.1-.7-2.4-.7-3.7 0-1.4.2-2.6.7-3.7.5-1.1 1.1-2.1 1.9-2.9.8-.8 1.7-1.4 2.8-1.9 1.1-.5 2.3-.7 3.5-.7h15.2l6-9.9H148c-2.6 0-5.1.4-7.3 1.3s-4.2 2.2-5.8 3.8c-1.6 1.7-2.9 3.6-3.8 5.9-.9 2.3-1.4 4.8-1.4 7.6s.5 5.4 1.4 7.7c.9 2.4 2.2 4.4 3.8 6.2 1.6 1.7 3.6 3.1 5.8 4.1 2.2 1 4.7 1.5 7.3 1.5h15.2l6.3-9.9H148zM112.1 209.3v20.3c13.1-9 24.1-18.7 33.8-28.6h-24.1c-3.1 2.8-6.3 5.6-9.7 8.3zM126.3 186.3h-21.5c-1.3 0-2.4-.2-3.5-.6-1.1-.4-2-1.1-2.8-1.9-.8-.8-1.4-1.8-1.9-2.9-.4-1.1-.7-2.4-.7-3.7 0-1.4.2-2.6.7-3.7.5-1.1 1.1-2.1 1.9-2.9.8-.8 1.7-1.4 2.8-1.9 1.1-.5 2.3-.7 3.5-.7H120l6-9.9h-21.3c-2.6 0-5.1.4-7.3 1.3s-4.2 2.2-5.8 3.8c-1.6 1.7-2.9 3.6-3.8 5.9-.9 2.3-1.4 4.8-1.4 7.6s.5 5.3 1.4 7.7c.9 2.4 2.2 4.4 3.8 6.2 1.6 1.7 3.5 3.1 5.8 4.1 2.2 1 4.7 1.5 7.3 1.5h15.2l6.4-9.9zM76.7 195.3c1.6-.6 3-1.4 4.1-2.4 1.1-1.1 1.8-2.4 2.3-3.9.5-1.6.7-3.3.7-5.2 0-3.5-1-6.1-2.9-7.8-1.9-1.7-4.8-2.6-8.5-2.6H55.9c-1.1 0-1.9-.2-2.4-.6-.5-.4-.8-1.1-.8-2.1s.3-1.7.8-2.1c.5-.4 1.3-.6 2.4-.6h21l6-9.9H56.6c-2.3 0-4.3.3-5.9.9-1.6.6-3 1.4-4 2.4s-1.8 2.3-2.3 3.7c-.5 1.5-.7 3.1-.7 4.9 0 1.6.2 3.1.7 4.5.4 1.3 1.1 2.5 2.1 3.4.9.9 2.1 1.7 3.5 2.2 1.4.5 3.1.8 5.1.8h16.5c1.1 0 1.9.2 2.4.6.5.4.8 1.1.8 2.1s-.3 1.7-.8 2.1c-.5.4-1.3.7-2.4.7h-23l-6 9.8h28.2c2.3 0 4.2-.3 5.9-.9z"></path>
        <path d="M76.7 195.3c1.6-.6 3-1.4 4.1-2.4 1.1-1.1 1.8-2.4 2.3-3.9.5-1.6.7-3.3.7-5.2 0-3.5-1-6.1-2.9-7.8-1.9-1.7-4.8-2.6-8.5-2.6H55.9c-1.1 0-1.9-.2-2.4-.6-.5-.4-.8-1.1-.8-2.1s.3-1.7.8-2.1c.5-.4 1.3-.6 2.4-.6h21l6-9.9H56.6c-2.3 0-4.3.3-5.9.9-1.6.6-3 1.4-4 2.4s-1.8 2.3-2.3 3.7c-.5 1.5-.7 3.1-.7 4.9 0 1.6.2 3.1.7 4.5.4 1.3 1.1 2.5 2.1 3.4.9.9 2.1 1.7 3.5 2.2 1.4.5 3.1.8 5.1.8h16.5c1.1 0 1.9.2 2.4.6.5.4.8 1.1.8 2.1s-.3 1.7-.8 2.1c-.5.4-1.3.7-2.4.7h-23l-6 9.8h28.2c2.3 0 4.2-.3 5.9-.9zM30.4 179.4c0 1.3-.2 2.5-.5 3.4-.3.9-.8 1.7-1.6 2.3-.7.6-1.7 1.1-2.9 1.3-1.2.3-2.7.4-4.5.4-2.4 0-4.4-.1-5.9-.4-1.5-.2-2.7-.7-3.5-1.3-.8-.6-1.4-1.4-1.7-2.3-.3-.9-.5-2.1-.5-3.5V158H0v21.3c0 2.9.4 5.4 1.2 7.6.8 2.2 2.1 3.9 3.8 5.4 1.7 1.4 3.9 2.5 6.5 3.2 2.6.7 5.8 1.1 9.4 1.1 12.6 0 18.9-5.8 18.9-17.3V158h-9.4v21.4z"></path>
        <path d="M30.4 179.4c0 1.3-.2 2.5-.5 3.4-.3.9-.8 1.7-1.6 2.3-.7.6-1.7 1.1-2.9 1.3-1.2.3-2.7.4-4.5.4-2.4 0-4.4-.1-5.9-.4-1.5-.2-2.7-.7-3.5-1.3-.8-.6-1.4-1.4-1.7-2.3-.3-.9-.5-2.1-.5-3.5V158H0v21.3c0 2.9.4 5.4 1.2 7.6.8 2.2 2.1 3.9 3.8 5.4 1.7 1.4 3.9 2.5 6.5 3.2 2.6.7 5.8 1.1 9.4 1.1 12.6 0 18.9-5.8 18.9-17.3V158h-9.4v21.4zM70.7 201c9.7 9.9 20.6 19.6 33.8 28.6v-20.3c-3.4-2.7-6.6-5.5-9.7-8.3H70.7zM65.6 153.1c-1.2-11.7 2.7-21.3 9.4-28 18.1-18.3 52.1-8.9 51.3 17.3 0 .1 52.2-36.2-10.8-61.9C131.2 59.6 85 43 78 41c15.3-9.1 24.7-16.9 26.5-18.3V0C93 11.2 58.9 37.8 13.5 48.8c0 0-5 50 23.1 104.3h29zm2.8-72.9l5.5-17.1 5.4 17.1h18.3l-14.7 11 5.7 17.6L73.9 98l-14.7 10.8L65 91.2l-14.9-11h18.3z"></path>
      </g>
    </svg>
  ),
  xInCircle: (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 512 512"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>error</title>
      <g id="Page-1" stroke="none" stroke-width="1" fill-rule="evenodd">
        <g id="add" transform="translate(42.666667, 42.666667)">
          <path
            d="M213.333333,3.55271368e-14 C331.136,3.55271368e-14 426.666667,95.5306667 426.666667,213.333333 C426.666667,331.136 331.136,426.666667 213.333333,426.666667 C95.5306667,426.666667 3.55271368e-14,331.136 3.55271368e-14,213.333333 C3.55271368e-14,95.5306667 95.5306667,3.55271368e-14 213.333333,3.55271368e-14 Z M213.333333,42.6666667 C119.232,42.6666667 42.6666667,119.232 42.6666667,213.333333 C42.6666667,307.434667 119.232,384 213.333333,384 C307.434667,384 384,307.434667 384,213.333333 C384,119.232 307.434667,42.6666667 213.333333,42.6666667 Z M262.250667,134.250667 L292.416,164.416 L243.498667,213.333333 L292.416,262.250667 L262.250667,292.416 L213.333333,243.498667 L164.416,292.416 L134.250667,262.250667 L183.168,213.333333 L134.250667,164.416 L164.416,134.250667 L213.333333,183.168 L262.250667,134.250667 Z"
            id="error"
          ></path>
        </g>
      </g>
    </svg>
  ),
  xInClipBoard: (
    <svg
      width="800px"
      height="800px"
      fill="white"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M557.248 608l135.744-135.744-45.248-45.248-135.68 135.744-135.808-135.68-45.248 45.184L466.752 608l-135.68 135.68 45.184 45.312L512 653.248l135.744 135.744 45.248-45.248L557.312 608zM704 192h160v736H160V192h160v64h384v-64zm-320 0V96h256v96H384z" />
    </svg>
  ),
  backArrow: (
    <svg
      width="25px"
      height="25px"
      fill="white"
      viewBox="0 0 512 512"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style type="text/css"></style>

      <g id="Layer_1" />

      <g id="Layer_2">
        <g>
          <path d="M217,129.88c-6.25-6.25-16.38-6.25-22.63,0L79.61,244.64c-0.39,0.39-0.76,0.8-1.11,1.23    c-0.11,0.13-0.2,0.27-0.31,0.41c-0.21,0.28-0.42,0.55-0.62,0.84c-0.14,0.21-0.26,0.43-0.39,0.64c-0.14,0.23-0.28,0.46-0.41,0.7    c-0.13,0.24-0.24,0.48-0.35,0.73c-0.11,0.23-0.22,0.45-0.32,0.68c-0.11,0.26-0.19,0.52-0.28,0.78c-0.08,0.23-0.17,0.46-0.24,0.69    c-0.09,0.29-0.15,0.58-0.22,0.86c-0.05,0.22-0.11,0.43-0.16,0.65c-0.08,0.38-0.13,0.76-0.17,1.14c-0.02,0.14-0.04,0.27-0.06,0.41    c-0.11,1.07-0.11,2.15,0,3.22c0.01,0.06,0.02,0.12,0.03,0.18c0.05,0.46,0.12,0.92,0.21,1.37c0.03,0.13,0.07,0.26,0.1,0.39    c0.09,0.38,0.18,0.76,0.29,1.13c0.04,0.13,0.09,0.26,0.14,0.4c0.12,0.36,0.25,0.73,0.4,1.09c0.05,0.11,0.1,0.21,0.15,0.32    c0.17,0.37,0.34,0.74,0.53,1.1c0.04,0.07,0.09,0.14,0.13,0.21c0.21,0.38,0.44,0.76,0.68,1.13c0.02,0.03,0.04,0.06,0.06,0.09    c0.55,0.81,1.18,1.58,1.89,2.29l114.81,114.81c3.12,3.12,7.22,4.69,11.31,4.69s8.19-1.56,11.31-4.69c6.25-6.25,6.25-16.38,0-22.63    l-87.5-87.5h291.62c8.84,0,16-7.16,16-16s-7.16-16-16-16H129.51L217,152.5C223.25,146.26,223.25,136.13,217,129.88z" />
        </g>
      </g>
    </svg>
  ),
  ComputerError: (
    <svg
      height="100px"
      width="100px"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 452.986 452.986"
    >
      <g>
        <g>
          <g>
            <path
              d="M0,0v452.986h452.986V0H0z M403.223,24.655c8.132,0,14.668,6.558,14.668,14.625
				c0,8.111-6.536,14.711-14.668,14.711c-8.089,0-14.668-6.601-14.668-14.711C388.576,31.234,395.134,24.655,403.223,24.655z
				 M352.035,24.655c8.046,0,14.647,6.558,14.647,14.625c0,8.111-6.601,14.711-14.647,14.711c-8.132,0-14.668-6.601-14.668-14.711
				C337.389,31.234,343.903,24.655,352.035,24.655z M417.955,418.624H35.053V85.701h382.903V418.624z"
            />
            <path
              d="M226.493,398.952c80.394,0,145.495-65.101,145.495-145.43s-65.101-145.495-145.495-145.495
				c-80.373,0-145.516,65.165-145.516,145.495C80.977,333.873,146.12,398.952,226.493,398.952z M142.044,198.516l29.444-29.444
				l55.005,55.027l54.984-55.027l29.379,29.444L255.894,253.5l54.984,54.941l-29.423,29.444l-54.962-54.962l-55.005,54.984
				l-29.444-29.444l54.984-54.941L142.044,198.516z"
            />
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </g>
    </svg>
  )
};
