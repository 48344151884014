import { combineReducers } from 'redux';
import users from './users';
import tokens from './tokens';
import verifications from './verifications';

const createReducer = (extraReducerObjects = {}) =>
  combineReducers({
    users,
    tokens,
    verifications,
    ...extraReducerObjects
  });

export default createReducer;
