import { EmbedService, Messages } from '@delta-defense/delta-utils';

export async function getAnalyticsProperties(deltaAnalytics = window['deltaAnalytics'], embedService = EmbedService.instance()) {
  if (embedService.isEmbedded) {
    return (await embedService.messageParent(Messages.GetAnalyticsProperties)) || {};
  } else if (deltaAnalytics) {
    return deltaAnalytics.aggregateAvailableProperties() || {};
  } else {
    return {};
  }
}
