import React, { Component } from 'react';

import '../AdditionalInfo/AdditionalInfo.scss';
import appleBadge from '../../assets/badge-apple-appstore.png';
import playBadge from '../../assets/badge-google-play.png';

class GetMobileApp extends Component {
  render() {
    return (
      <div className="get-mobile-app mb-10">
        <div className="get-mobile-app__horizontal-line" />

        <h3 className="get-mobile-app__h3 mx-0 my-0">Download Our Free Mobile Apps</h3>
        <p className="get-mobile-app__p mb-4">
          Get instant access to lifesaving self-defense resources with the USCCA’s free Protector
          Academy and Reciprocity apps. Help secure a safer tomorrow by downloading them both today.
        </p>

        <div>
          <a href="https://apps.apple.com/us/developer/delta-defense-llc/id865204445">
            <img
              id="appDownloadiOS"
              className="app-store-icons mr-3"
              src={appleBadge}
              alt="Generic placeholder"
            />
          </a>

          <a href="https://play.google.com/store/apps/developer?id=The+USCCA">
            <img
              id="appDownloadAndroid"
              className="app-store-icons"
              src={playBadge}
              alt="Generic placeholder"
            />
          </a>
        </div>
      </div>
    );
  }
}

export default GetMobileApp;
