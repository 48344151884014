export const redirectToParam = 'redirectTo';
export const alternateRedirectToParam = 'redirect_to';
export const memberRedirectToParam = 'redirectMemberTo';
export const nonMemberRedirectToParam = 'redirectNonMemberTo';
export const ignoreEmbedParam = 'ignoreEmbedAndRenderFull';

export enum LoginCookieKeys {
  SSO = 'uscca_sso',
  UsccaSess = 'SESSusccaaccess'
}
