import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';

import { verifyEmail, resetError } from '../../redux/modules/verifications';
import { addEmail } from '../../redux/modules/users';
import { LoadingSpinner } from '../RedirectChecker/RedirectChecker';
import Title from '../Title/Title';
import InputGroup from '../InputGroup/InputGroup';
import AuthButton from '../AuthButton/AuthButton';
import EmailChange from '../AdditionalInfo/EmailChange';
import AuthAlert from '../AuthAlert/AuthAlert';
import Loading from '../Loading/Loading';
import DefaultLinks from '../NavigationLinks/DefaultLinks';
import '../Forms/Forms.scss';
import { DefaultErrorPage } from '../ErrorPage/defaultErrorPage';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEmailVerified: false
    };
  }

  componentDidMount() {
    // when switching to this route user error go away
    // @ts-expect-error TS(2339) FIXME: Property 'resetError' does not exist on type 'Read... Remove this comment to see the full error message
    this.props.resetError();
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'loading' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { loading, statusCode, errors, touched, values, handleKeyUp, handleChange, resetError } = this.props;

    // @ts-expect-error TS(2339) FIXME: Property 'isEmailVerified' does not exist on type ... Remove this comment to see the full error message
    if (this.state.isEmailVerified) {
      // @ts-expect-error TS(2339) FIXME: Property 'verificationId' does not exist on type '... Remove this comment to see the full error message
      const verificationId = this.props.verificationId;
      return <Redirect push to={{ pathname: `/forgot-password/${verificationId}` }} />;
    }

    const handledErrorCodes = [404];
    if (statusCode === 201) {
      this.setState({ isEmailVerified: true });
      return <LoadingSpinner />;
    } else if (!!statusCode && !handledErrorCodes.includes(statusCode)) {
      return <DefaultErrorPage errorStatus={statusCode}/>;
    }

    return (
      <>
        <Form>
          <Title name="Reset Password" />
          <InputGroup
            id="resetPasswordEmail"
            name="email"
            label="Email"
            type="email"
            value={values.email}
            error={errors.email}
            touched={touched.email}
            onKeyUp={handleKeyUp(resetError)}
            onChange={handleChange}
            autoComplete="email"
          />
          {statusCode === 404 && (
            <AuthAlert message="There is no account for the email you entered." />
          )}

          <DefaultLinks />

          <AuthButton
            id="resetPasswordVerificationButton"
            color="btn-gold"
            text={loading ? <Loading /> : 'Send Verification Email'}
            disabled={loading}
          />
        </Form>

        <EmailChange />
      </>
    );
  }
}

const ForgotPasswordContainer = withFormik({
  // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
  mapPropsToValues: (props) => ({ email: props.email }),
  validationSchema: Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required')
  }),

  validateOnBlur: false,

  handleSubmit: async (values, { props }) => {
    const { email } = values;

    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    props.addEmail(email);
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    props.verifyEmail(email);
  },
  displayName: 'Forgot Password'
  // @ts-expect-error: FIXME: wat
})(ForgotPassword);

const mapStateToProps = ({
  verifications: { verificationId, loading, statusError, statusCode },
  users: { email }
}) => {
  return {
    email,
    loading,
    statusError,
    statusCode,
    verificationId
  };
};

export default connect(mapStateToProps, { verifyEmail, addEmail, resetError })(
  ForgotPasswordContainer
);
