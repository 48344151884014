import React, { useEffect, useState } from 'react';

import { CookieKeys, EmbeddedCookieHandler, EmbedService, Messages } from '@delta-defense/delta-utils';
import { RedirectService } from '../../core/services/redirectService';
import { EnvironmentService } from '../../core/services/environmentService';
import Title from '../Title/Title';
import GetMobileApp from '../AdditionalInfo/GetMobileApp';
import AuthButton from '../AuthButton/AuthButton';

import '../CompletedForms/CompletedForms.scss';
import { LoadingSpinner } from '../RedirectChecker/RedirectChecker';

const PasswordResetSuccess = ({
  embedService = EmbedService.instance(),
  cookieService = EmbeddedCookieHandler.instance(),
  redirectService = RedirectService.Instance(),
  environmentService = EnvironmentService.Instance()
}) => {
  const [redirectDestination, setRedirectDestination] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [leadToken, setLeadToken] = useState('');

  useEffect(() => {
    (async () => {
      const redirectUrl = await redirectService.resolveRedirectUrl();
      const rawAccessToken = await cookieService.getCookie(CookieKeys.AccessToken);
      setAccessToken(rawAccessToken);
      setRedirectDestination(redirectUrl);
      setLeadToken(await cookieService.getCookie(CookieKeys.LeadFields));
    })();
  }, []);

  const isAcademyRedirect =
    redirectDestination.includes('academy') || redirectDestination.includes('training');

  return !!redirectDestination ? (
    <>
      <div className="mt-5">
        <Title name="SUCCESS!" />

        <p className="form-text">
          We have updated your password. Click the button below to access{' '}
          {isAcademyRedirect ? 'our Protector Academy to' : 'your USCCA Dashboard and'} preview the
          exceptional training content available to our Members.
        </p>

        {embedService.isEmbedded ? (
          <AuthButton
            id="myAccountButton"
            color="btn-gold"
            text="Continue"
            onClick={() => {
              embedService.messageParent(Messages.Redirect, {
                action: 'login',
                redirect_url: redirectDestination
              });
            }}
          />
        ) : (
          <>
            <form
              className="secret-form"
              method="POST"
              action={environmentService.ddComUrl + '/sso'}
            >
              <input type="text" name="action" value="login" readOnly />
              <input type="text" name="redirect_url" value={redirectDestination} readOnly />
              <input type="text" name="uscca_access" value={accessToken} readOnly />
              <input type="text" name="uscca_lead_fields" value={leadToken} readOnly />
              <AuthButton id="myAccountButton" color="btn-gold" text="Continue" />
            </form>
            <GetMobileApp />
          </>
        )}
      </div>
    </>
  ) : (
    <LoadingSpinner />
  );
};

export default PasswordResetSuccess;
