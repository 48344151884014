import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createReducer from './modules/reducer';
import api from './middleware/api';

const composeEnhancers =
  typeof window === 'object' &&
  // @ts-expect-error TS(2339) FIXME: Property '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' do... Remove this comment to see the full error message
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    // @ts-expect-error TS(2339) FIXME: Property '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' do... Remove this comment to see the full error message
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose);

export function configureStore(preloadedState: Object = {}) {
  const middlewares = [];
  const store = createStore(
    createReducer(),
    preloadedState,
    composeEnhancers(applyMiddleware(thunk, api, ...middlewares))
  );
  return store;
}

export default configureStore;
