/* eslint-disable max-lines */
// FIXME: reduce lines in file

import { Form, FormikProps, withFormik } from 'formik';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import { resetError as resetUserError, updateUser, addEmail } from '../../redux/modules/users';
import { resetError, verifyEmailAuthCode, verifyEmail } from '../../redux/modules/verifications';
import AuthAlert from '../AuthAlert/AuthAlert';
import AuthButton from '../AuthButton/AuthButton';
import PasswordResetSuccess from '../CompletedForms/PasswordResetSuccess';
import { DefaultErrorPage } from '../ErrorPage/defaultErrorPage';
import InputGroup from '../InputGroup/InputGroup';
import Loading from '../Loading/Loading';
import { LoadingSpinner } from '../RedirectChecker/RedirectChecker';
import Title from '../Title/Title';
import './CreateNewPassword.scss';
import { Link, Redirect } from 'react-router-dom';
import { CookieUtilities, decodeToken, EmbeddedCookieHandler, ICookieUtilities, IEmbeddedCookieHandler } from '@delta-defense/delta-utils';

const verificationErrorCodeMessages = {
  410: 'The code you\'ve entered has expired',
  422: 'The code you entered doesn\'t match the email',
  429: 'The code you have entered expired due to "too many invalid attempts."'
};
const defaultVerificationError = 'Something went wrong while confirming your verification code. Please try again later.';

export const getVerificationErrorMessage = (code?: string | number) => (code && verificationErrorCodeMessages[code]) || defaultVerificationError;

interface CreateNewPasswordFormValues {
  accountAuthorizationCode: string
  password: string
  passwordConfirmation: string
}

interface CreateNewPasswordFormProps extends FormikProps<CreateNewPasswordFormValues> {
  email: string
  userLoading: boolean
  verificationsLoading: boolean
  userStatusCode: number
  verificationsStatusCode?: number
  errors: {
    passwordConfirmation: any
    password: any
    accountAuthorizationCode: any
  }
  touched: any
  values: any
  handleKeyUp: any
  handleChange: any
  resetError: any
  resetUserError: any
  status: any
  cookieService: IEmbeddedCookieHandler
  cookieUtilities: ICookieUtilities
  verifyEmail: typeof verifyEmail
  verifyEmailAuthCode: typeof verifyEmailAuthCode,
  addEmail: typeof addEmail
  forceDebugMode?: boolean
  verificationId: string
}

interface CreateNewPasswordState {
  isPasswordResetSuccessful: boolean
  isExpiredVerificationCheck: boolean
}

class CreateNewPassword extends Component<CreateNewPasswordFormProps, CreateNewPasswordState> {
  private expiredResponseCodes = new Set([
    404,
    410,
    429
  ])

  constructor(props) {
    super(props);
    this.state = {
      isPasswordResetSuccessful: false,
      isExpiredVerificationCheck: false
    };
  }

  private getNewCodeLink(verificationsStatusCode?: number) {
    if (verificationsStatusCode === 422) {
      return;
    } else {
      return (
        <>
          Get new code{' '}
          <Link to={`/forgot-password${window.location.search}`} className="link">
          here.
          </Link>
        </>
      );
    }
  }

  private hasVerificationError(verificationsStatusCode?: number) {
    return !!verificationsStatusCode && verificationsStatusCode >= 400;
  }

  private isVerificationIdExpired(verificationsStatusCode?: number) {
    return this.state.isExpiredVerificationCheck &&
      verificationsStatusCode &&
      this.expiredResponseCodes.has(verificationsStatusCode);
  }

  private userHasError(userStatusCode?: number) {
    return !!userStatusCode && userStatusCode !== 404;
  }

  private shouldShowErrorAlert(verificationError?: boolean) {
    return verificationError && !this.state.isExpiredVerificationCheck;
  }

  private shouldShowPasswordMessage() {
    return this.props.touched && !this.props.errors.password;
  }

  private isLoading() {
    return this.props.verificationsLoading || this.props.userLoading;
  }

  componentDidMount() {
    // for testing errors, if there's a will there's a way
    if (this.props.forceDebugMode) {
      return;
    }

    // when switching to this route user error go away
    this.props.resetError();
    this.props.resetUserError();

    // Check if the verification ID is valid or if it has expired.
    // We *need* to pass an auth code to this endpoint, so we're
    // using a fake code that won't normally match. If it happens
    // to it shouldn't be a big deal; we're just checking for a
    // 404 or 429 response code.
    const FAKE_AUTH_CODE = '000000';

    // @ts-expect-error FIXME: types from react-router
    const verificationId = this.props.match.params.verificationId;
    this.props.verifyEmailAuthCode(verificationId, FAKE_AUTH_CODE);
    this.setState({ isExpiredVerificationCheck: true });
  }

  render() {
    const {
      email,
      userStatusCode,
      verificationsStatusCode,
      errors,
      touched,
      values,
      handleKeyUp,
      handleChange,
      resetError,
      status
    } = this.props;

    // Don't want to flash the reset password form if we need to redirect
    // to the forgot password page; this will prevent that.
    if (this.isLoading()) {
      return <LoadingSpinner />;
    }

    if (this.state.isPasswordResetSuccessful) {
      return <PasswordResetSuccess />;
    }

    if (userStatusCode === 200) {
      this.setState({ isPasswordResetSuccessful: true });
      return <LoadingSpinner />;
    } else if (this.userHasError(userStatusCode)) {
      return <DefaultErrorPage errorStatus={userStatusCode} />;
    }

    if (this.isVerificationIdExpired(verificationsStatusCode)) {
      return <Redirect push to={{ pathname: '/forgot-password' }} />;
    }

    if (this.state.isExpiredVerificationCheck) {
      this.setState({ isExpiredVerificationCheck: false });
      this.props.resetError();
    }

    const verificationError = this.hasVerificationError(verificationsStatusCode);
    const verificationErrorMessage = getVerificationErrorMessage(verificationsStatusCode);

    return (
      <>
        <Title name="Reset Password" />
        <p className="form-text">
          For your security, we've sent an authorization code to your email at {email}.
          If you've listed a mobile phone number, we have sent the authorization code via text.
          Enter the code below and create a new password.
          <b>This code is only valid for 1 hour.</b>
        </p>

        <Form>
          <InputGroup
            id="accountAuthorizationCode"
            name="accountAuthorizationCode"
            label="Authorization Code"
            type="tel"
            maxLength="6"
            value={values.accountAuthorizationCode}
            error={errors.accountAuthorizationCode}
            touched={touched.accountAuthorizationCode}
            onKeyUp={(e) => {
              e.preventDefault();
              handleKeyUp(resetError);
            }}
            onChange={handleChange}
            autoComplete="one-time-code"
          />
          {this.shouldShowErrorAlert(verificationError) && (
            <AuthAlert
              message={verificationErrorMessage}
              link={this.getNewCodeLink(verificationsStatusCode)}
            />
          )}
          <InputGroup
            id="createAccountPassword"
            name="password"
            label="Password"
            type="password"
            value={values.password}
            error={errors.password}
            touched={touched.password}
            onChange={handleChange}
            autoComplete="new-password"
            passwordUnmasked={status?.passwordUnmasked}
          />
          {this.shouldShowPasswordMessage() && (
            <p className="mt-1 form-text">Use at least 8 characters</p>
          )}

          <InputGroup
            id="createAccountReEnterPassword"
            name="passwordConfirmation"
            label="Password Confirmation"
            type="password"
            value={values.passwordConfirmation}
            error={errors.passwordConfirmation}
            touched={touched.passwordConfirmation}
            onChange={handleChange}
            passwordUnmasked={status?.passwordUnmasked}
          />

          <AuthButton
            id="resetPasswordSave"
            color="btn-gold"
            text={this.isLoading() ? <Loading /> : 'Save Password'}
            disabled={this.isLoading()}
          />
        </Form>
      </>
    );
  }
}

const CreateNewPasswordContainer = withFormik<CreateNewPasswordFormProps, CreateNewPasswordFormValues>({
  mapPropsToValues: () => ({
    accountAuthorizationCode: '',
    password: '',
    passwordConfirmation: ''
  }),

  validationSchema: Yup.object().shape({
    accountAuthorizationCode: Yup.string()
      .min(6, 'Authorization Code requires six digits')
      .matches(/[0-9.]/, 'Only numbers are valid')
      .required('Authorization Code is required'),

    password: Yup.string()
      .min(8, 'Password needs at least 8 characters.')
      .max(40, 'Password needs less than 40 characters.')
      .required('Password is required'),

    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], "Passwords don't match")
      .required('Password confirmation is required')
  }),

  validateOnBlur: false,

  handleSubmit: async (values, { props, setStatus }) => {
    // this set status is used to pass state from the handleSubmit function to the component
    setStatus({ passwordUnmasked: false });
    const { password, accountAuthorizationCode } = values;
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    const verificationId = props.match.params.verificationId || props.verificationId;
    try {
      const getToken = await props.verifyEmailAuthCode(
        verificationId,
        accountAuthorizationCode,
        true
      );
      // @ts-expect-error FIXME: data type
      const token = getToken?.data?.attributes?.token;
      // @ts-expect-error FIXME: data type
      const ttl = getToken?.data?.attributes?.ttl;

      const userId = decodeToken(token)?.userId || '';

      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      await props.updateUser(token, userId, password, true);
      await props.cookieUtilities.setAccessToken(token);
    } catch (error) {
      // FIXME: remove console statement
      // eslint-disable-next-line no-console
      console.log(error);
    }
    // this set status is used to reset the passwordUnmasked state
    setStatus({ passwordUnmasked: undefined });
  },

  displayName: 'Create New Password'
})(CreateNewPassword);

const mapStateToProps = ({
  users: {
    loading: userLoading,
    statusCode: userStatusCode,
    statusError: userStatusError,
    email
  },
  verifications: {
    verificationId,
    loading: verificationsLoading,
    statusCode: verificationsStatusCode,
    statusError: verificationsStatusError
  }
}) => {
  return {
    email,
    verificationId,
    userLoading,
    verificationsLoading,
    userStatusCode,
    verificationsStatusCode,
    userStatusError,
    verificationsStatusError
  };
};

CreateNewPasswordContainer.defaultProps = {
  cookieService: EmbeddedCookieHandler.instance(),
  cookieUtilities: CookieUtilities.instance()
};

export default connect(mapStateToProps, {
  updateUser,
  verifyEmailAuthCode,
  verifyEmail,
  addEmail,
  resetError,
  resetUserError
})(CreateNewPasswordContainer);
