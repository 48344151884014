/* eslint-disable complexity */
import React, { Component } from 'react';
import { Field } from 'formik';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  faQuestionCircle,
  faEye,
  faEyeSlash
} from '@fortawesome/free-solid-svg-icons';
import { ToolTip } from '@delta-defense/b2c-shared-react/components/toolTip/module';

import '../InputGroup/InputGroup.scss';

interface InputGroupProps {
  id: string
  name: string
  label: string
  type: string
  maxLength?: string
  value: any
  error: any
  touched: any
  onKeyUp?: (e: Event) => void
  onChange?: (e: Event) => void
  autoComplete?: string
  passwordUnmasked?: any
  pattern?: string
  optional?: any
  innerRef?: (ref: any) => void
}

class InputGroup extends Component<InputGroupProps> {
  constructor(props) {
    super(props);
    this.state = { showPasswordText: false };
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.passwordUnmasked === false) {
      return { showPasswordText: false };
    }

    return null;
  }

  handleTogglePassword = () => {
    // @ts-expect-error TS(2339) FIXME: Property 'showPasswordText' does not exist on type... Remove this comment to see the full error message
    this.setState({ showPasswordText: !this.state.showPasswordText });
  };

  getType = () => {
    if (this.props?.name.toLowerCase().includes('password')) {
      // @ts-expect-error TS(2339) FIXME: Property 'showPasswordText' does not exist on type... Remove this comment to see the full error message
      return this.state.showPasswordText ? 'text' : 'password';
    }

    return this.props.type;
  };

  render() {
    const props = this.props;

    // @ts-expect-error TS(2339) FIXME: Property 'error' does not exist on type 'Readonly<... Remove this comment to see the full error message
    if (props.error || props.warning) {
      library.add(faExclamationCircle);
    }

    if (props.optional) {
      library.add(faQuestionCircle);
    }

    if (props.type === 'password') {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'IconDefinition[]' is not assigna... Remove this comment to see the full error message
      library.add([faEye, faEyeSlash]);
    }

    return (
      <div
        // @ts-expect-error TS(2339) FIXME: Property 'touched' does not exist on type 'Readonl... Remove this comment to see the full error message
        className={`form-field ${props.touched && (props.error || props.warning) ? 'invalid' : ''}`}
      >
        {props.touched &&
          ((props.error && (
            <span className="form-field__validate form-field__validate--error">{props.error}</span>
          )) ||
            // @ts-expect-error TS(2339) FIXME: Property 'warning' does not exist on type 'Readonl... Remove this comment to see the full error message
            (props.warning && (
              <span className="form-field__validate form-field__validate--warning">
                {/* @ts-expect-error TS(2339) FIXME: Property 'warning' does not exist on type 'Readonl... Remove this comment to see the full error message */}
                {props.warning}
              </span>
            )))}

        {props.optional && (
          <div className="d-flex justify-content-end align-items-center">
            <ToolTip
              description="Allows the sending of account authorization codes to your mobile device"
              direction="right"
            >
              <FontAwesomeIcon icon={faQuestionCircle} size="1x" className="form-field__tooltip" />
            </ToolTip>
            <span className="form-field__optional">
              <em>Optional</em>
            </span>
          </div>
        )}
        <label className="form-field__label" htmlFor={props.label}>
          <Field
            className="form-field__input"
            component="input"
            // @ts-expect-error TS(2339) FIXME: Property 'disabled' does not exist on type 'Readon... Remove this comment to see the full error message
            disabled={props.disabled}
            id={props.id}
            label={props.label}
            name={props.name}
            innerRef={props.innerRef}
            type={this.getType()}
            value={props.value}
            maxLength={props.maxLength}
            // @ts-expect-error TS(2339) FIXME: Property 'placeholder' does not exist on type 'Rea... Remove this comment to see the full error message
            placeholder={props.placeholder}
            onKeyUp={props.onKeyUp}
            onChange={props.onChange}
            // @ts-expect-error TS(2339) FIXME: Property 'onFocus' does not exist on type 'Readonl... Remove this comment to see the full error message
            onFocus={props.onFocus}
            autoComplete={props.autoComplete}
          />
          <span className={`${props.optional ? 'optional-label' : ''}`}>{props.label}</span>

          {props.type === 'password' && (
            <div
              className={`form-field__password form-field__svg-icon form-field__svg-icon${props.touched && props.error ? '--password' : ''
              }`}
            >
              <FontAwesomeIcon
                onClick={this.handleTogglePassword}
                // @ts-expect-error TS(2339) FIXME: Property 'showPasswordText' does not exist on type... Remove this comment to see the full error message
                icon={this.state.showPasswordText ? faEyeSlash : faEye}
                size="1x"
              />
            </div>
          )}

          {props.touched && props.error && (
            <div className={`form-field__svg-icon${props.optional ? '--optional' : ''}`}>
              <FontAwesomeIcon
                icon={faExclamationCircle}
                size="1x"
                className="form-field__svg-icon--error"
              />
            </div>
          )}
        </label>
      </div>
    );
  }
}

export default InputGroup;
