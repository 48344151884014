import React, { Component, createRef } from 'react';
import { EnvironmentService } from '../../core/services/environmentService';
import { EmbedService, Messages } from '@delta-defense/delta-utils';
import Loading from '../Loading/Loading';
import './SingleSignOn.scss';

class SingleSignOn extends Component {
	state = {
	  // @ts-expect-error TS(2339) FIXME: Property 'redirect_url' does not exist on type 'Re... Remove this comment to see the full error message
	  redirect_url: this.props.redirect_url
	};
	submitButton = createRef();

	componentDidMount() {
	  // @ts-expect-error TS(2339) FIXME: Property 'embedService' does not exist on type 'Re... Remove this comment to see the full error message
	  if (this.props.embedService.isEmbedded) {
	    // @ts-expect-error TS(2339) FIXME: Property 'embedService' does not exist on type 'Re... Remove this comment to see the full error message
	    this.props.embedService.messageParent(Messages.Redirect, {
	      // @ts-expect-error TS(2339) FIXME: Property 'action' does not exist on type 'Readonly... Remove this comment to see the full error message
	      action: this.props.action,
	      // @ts-expect-error TS(2339) FIXME: Property 'redirect_url' does not exist on type 'Re... Remove this comment to see the full error message
	      redirect_url: this.props.redirect_url
	    });
	  } else {
	    (async () => {
	      // @ts-expect-error TS(2339) FIXME: Property 'locationSearch' does not exist on type '... Remove this comment to see the full error message
	      const params = new URLSearchParams(this.props.locationSearch);
	      if (params.has('verification')) {
	        try {
	          const verificationCodeResponse = await fetch('https://member-verification.uscca.workers.dev/get-verification-code', {
	            method: 'POST',
	            headers: {
	              // @ts-expect-error TS(2339) FIXME: Property 'uscca_access' does not exist on type 'Re... Remove this comment to see the full error message
	              'X-Auth-Token': this.props.uscca_access
	            }
	          });
	          const verificationCodeJson = await verificationCodeResponse.json();
	          // @ts-expect-error TS(2339) FIXME: Property 'redirect_url' does not exist on type 'Re... Remove this comment to see the full error message
	          const redirectUrlWithVerificationCode = new URL(this.props.redirect_url);
	          redirectUrlWithVerificationCode.searchParams.delete('verification');
	          redirectUrlWithVerificationCode.searchParams.set('verificationCode', verificationCodeJson.code);
	          this.setState({
	            redirect_url: redirectUrlWithVerificationCode.href
	          });
	        } catch (error) {
	          console.error('Unable to request verification code', error);
	        }
	      }

	      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
	      this.submitButton.current?.click();
	    })();
	  }
	}

	render() {
	  return (
	    <>
	      <div
	        style={{
	          height: '100vh',
	          display: 'flex',
	          alignItems: 'center',
	          justifyContent: 'center',
	          color: '#fff'
	        }}
	      >
	        <Loading size="2x" />
	      </div>
	      {/* @ts-expect-error TS(2339): Property 'environmentService' does not exist on ty... Remove this comment to see the full error message */}
	      <form className="sso-form" method="POST" action={this.props.environmentService.ddComUrl + '/sso'}>
	        {/* @ts-expect-error TS(2339): Property 'action' does not exist on type 'Readonly... Remove this comment to see the full error message */}
	        <input type="text" name="action" value={this.props.action} />
	        <input type="text" name="redirect_url" value={this.state.redirect_url} />
	        {/* @ts-expect-error TS(2339): Property 'uscca_access' does not exist on type 'Re... Remove this comment to see the full error message */}
	        <input type="text" name="uscca_access" value={this.props.uscca_access} />
	        {/* @ts-expect-error TS(2339): Property 'uscca_lead_fields' does not exist on typ... Remove this comment to see the full error message */}
	        <input type="text" name="uscca_lead_fields" value={this.props.uscca_lead_fields} />
	        {/* @ts-expect-error TS(2322) FIXME: Type 'RefObject<unknown>' is not assignable to typ... Remove this comment to see the full error message */}
	        <input ref={this.submitButton} type="submit" value="Submit" />
	      </form>
	    </>
	  );
	}
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
SingleSignOn.defaultProps = {
  environmentService: EnvironmentService.Instance(),
  embedService: EmbedService.instance(),
  locationSearch: window.location.search
};

export default SingleSignOn;
