import React, { Component } from 'react';

import '../AdditionalInfo/AdditionalInfo.scss';

class EmailChange extends Component {
  render() {
    return (
      <div className="email-change">
        <p className="font-weight-bold mt-0 mb-2">Has your email address changed?</p>
        <p className="mt-0 mb-0">
          If you no longer have access to the email address linked to your USCCA Account, you can
          contact our Customer Service Team at{' '}
          <a href="tel:8006749779" className="email-change__link" aria-label="Customer Service Number">
            800-674-9779
          </a>{' '}
          to help you access your USCCA account.
        </p>
      </div>
    );
  }
}

export default EmailChange;
