import Environments from '@delta-defense/client-environments';
import { CALL_API } from '../middleware/api';
import { Apis } from '@delta-defense/client-environments/environments/enums/apis';

//User Actions
const RESET_VERIFICATIONS = 'application/token/RESET_VERIFICATIONS';
const RESET_ERROR = 'application/verifications/RESET_ERROR';
const VERIFICATIONS_REQUEST = 'application/verifications/VERIFICATIONS_REQUEST';
const VERIFICATIONS_RESPONSE = 'application/verifications/VERIFICATIONS_RESPONSE';
const VERIFICATIONS_AUTH_RESPONSE = 'application/verifications/VERIFICATIONS_AUTH_RESPONSE';
const VERIFICATIONS_FAILURE = 'application/verifications/VERIFICATIONS_FAILURE';

//Initial State
const initialState = {
  loading: false,
  statusCode: '',
  statusError: '',
  verificationId: '',
  token: {}
};

//Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_VERIFICATIONS: {
      return initialState;
    }
    case VERIFICATIONS_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case VERIFICATIONS_RESPONSE: {
      const verificationId = action.response?.data?.id;
      return verificationId
        ? {
          ...state,
          loading: false,
          statusCode: action.status,
          statusError: '',
          verificationId
        }
        : {
          ...state,
          loading: false,
          statusCode: '500',
          statusError: 'No verification ID returned'
        };
    }
    case VERIFICATIONS_AUTH_RESPONSE: {
      const token = action.response?.data?.attributes;
      return token?.token
        ? {
          ...state,
          loading: false,
          statusCode: action.status,
          statusError: '',
          token
        }
        : {
          ...state,
          loading: false,
          statusCode: '500',
          statusError: 'No verification ID returned'
        };
    }
    case VERIFICATIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        statusCode: action.status,
        statusError: action.error
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        statusCode: '',
        statusError: ''
      };
    }

    default:
      return state;
  }
};

//Action Creators
export const resetToken = () => {
  return {
    type: RESET_VERIFICATIONS
  };
};

export const resetError = (error) => {
  return {
    type: RESET_ERROR,
    error
  };
};

//Async actions
export const verifyEmail = (email) => {
  return {
    [CALL_API]: {
      type: 'POST',
      endpoint: `${Environments.getProxyEndpointForApi(Apis.Auth)}/verifications`,
      options: {
        email
      },
      headers: {},
      actions: {
        request: VERIFICATIONS_REQUEST,
        success: VERIFICATIONS_RESPONSE,
        failure: VERIFICATIONS_FAILURE
      }
    }
  };
};

export const verifyEmailAuthCode = (id, code, shouldReturnBody = false) => {
  return {
    [CALL_API]: {
      type: 'POST',
      endpoint: `${Environments.getProxyEndpointForApi(Apis.Auth)}/verifications/${id}/tokens`,
      options: {
        code
      },
      headers: {},
      actions: {
        request: VERIFICATIONS_REQUEST,
        success: VERIFICATIONS_AUTH_RESPONSE,
        failure: VERIFICATIONS_FAILURE
      },
      shouldReturnBody
    }
  };
};
