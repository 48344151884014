import Environments from '@delta-defense/client-environments';

export const Domains = {
  Local: 'localhost',
  Dev: '.dev.usccadev.com',
  Staging: '.usccadev.com',
  cabelasshield: '.cabelasshield.com',
  Production: '.usconcealedcarry.com'
};

export const DDComUrls = {
  Local: 'http://0.0.0.0:4000',
  Dev: 'https://www.dev.deltadefensedev.com',
  Staging: 'https://www.deltadefensedev.com',
  Production: 'https://www.deltadefense.com'
};

export class EnvironmentService {
  // @ts-expect-error TS(6133) FIXME: '#instance' is declared but its value is never rea... Remove this comment to see the full error message
  static #instance = null;
  static Instance(environmentLibrary = Environments) {
    // @ts-expect-error TS(2551) FIXME: Property 'instance' does not exist on type 'typeof... Remove this comment to see the full error message
    return this.instance || (this.instance = new _EnvironmentService(environmentLibrary));
  }
  // @ts-expect-error TS(2551) FIXME: Property 'instance' does not exist on type 'typeof... Remove this comment to see the full error message
  static Destroy = () => (EnvironmentService.instance = null);
  instance: any;
}

class _EnvironmentService {
  #environmentLibrary;
  private hostnameOverride: string | null = null;

  constructor(environmentLibrary) {
    this.#environmentLibrary = environmentLibrary;
  }


  setHostnameForTesting(hostname: string | null) {
    this.hostnameOverride = hostname;
  }

  getHostname() {
    return this.hostnameOverride !== null ? this.hostnameOverride : window.location.hostname;
  }

  get isLocal() {
    return this.#environmentLibrary.isLocal();
  }
  get isDev() {
    return this.#environmentLibrary.isDev();
  }
  get isStaging() {
    return this.#environmentLibrary.isStaging();
  }
  get isProduction() {
    return this.#environmentLibrary.isProduction();
  }
  get isCabelasShield() {
    return this.getHostname().includes('cabelasshield.com');
  }

  get domain() {
    if (this.isLocal) {
      return Domains.Local;
    } else if (this.isDev) {
      return Domains.Dev;
    } else if (this.isStaging) {
      return Domains.Staging;
    } else if (this.isCabelasShield) {
      return Domains.cabelasshield;
    } else {
      return Domains.Production;
    }
  }

  get ddComUrl() {
    if (this.isLocal) {
      return DDComUrls.Local;
    } else if (this.isDev) {
      return DDComUrls.Dev;
    } else if (this.isStaging) {
      return DDComUrls.Staging;
    } else {
      return DDComUrls.Production;
    }
  }
}
