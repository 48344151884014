import { Environments } from '@delta-defense/client-environments';
import { Apis } from '@delta-defense/client-environments/environments/enums/apis';
import { CALL_API } from '../middleware/api';
import { getPlatform } from '../../utilities/getPlatform';

//User Actions
const RESET_TOKENS = 'application/token/RESET_TOKENS';
const RESET_ERROR = 'application/tokens/RESET_ERROR';
const TOKENS_REQUEST = 'application/tokens/TOKENS_REQUEST';
const TOKENS_RESPONSE = 'application/tokens/TOKENS_RESPONSE';
const TOKENS_FAILURE = 'application/tokens/TOKENS_FAILURE';

//Initial State
const initialState = {
  loading: false,
  statusCode: '',
  statusError: '',
  token: {}
};

//Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_TOKENS: {
      return initialState;
    }
    case TOKENS_REQUEST: {
      return {
        ...initialState,
        loading: true
      };
    }
    case TOKENS_RESPONSE: {
      const token = action.response?.data?.attributes;
      return token?.token ? {
        ...initialState,
        statusCode: action.status,
        token
      } : {
        ...initialState,
        loading: false,
        statusCode: 500,
        statusError: 'No token returned!'
      };
    }
    case TOKENS_FAILURE: {
      return {
        ...initialState,
        statusCode: action.status,
        statusError: action.error
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        statusCode: '',
        statusError: ''
      };
    }

    default:
      return state;
  }
};

//Action Creators
export const resetTokens = () => {
  return {
    type: RESET_TOKENS
  };
};

export const resetError = (error) => {
  return {
    type: RESET_ERROR,
    error
  };
};

//Async actions
export const userSignIn = (email, password, ttl) => {
  return {
    [CALL_API]: {
      type: 'POST',
      endpoint: `${Environments.getProxyEndpointForApi(Apis.Auth)}/tokens`,
      options: {
        email,
        password,
        ttl,
        meta: {
          // @ts-expect-error TS(2339) FIXME: Property 'deltaAnalytics' does not exist on type '... Remove this comment to see the full error message
          ...(window.deltaAnalytics?.aggregateAvailableProperties() || {}),
          email,
          referrer: document.referrer,
          method: 'USCCA',
          platform: getPlatform()
        }
      },
      headers: {},
      actions: {
        request: TOKENS_REQUEST,
        success: TOKENS_RESPONSE,
        failure: TOKENS_FAILURE
      }
    }
  };
};

export const createNewAccount = (
  name,
  surname,
  email,
  password,
  tracking
) => {
  return {
    [CALL_API]: {
      type: 'POST',
      endpoint: `${Environments.getProxyEndpointForApi(Apis.Auth)}/token`,
      options: {
        name,
        surname,
        email,
        password,
        tracking
      },
      headers: {},
      actions: {
        request: TOKENS_REQUEST,
        success: TOKENS_RESPONSE,
        failure: TOKENS_FAILURE
      }
    }
  };
};
