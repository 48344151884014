import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'url-search-params-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import FormContainer from './components/Forms/FormContainer';
import { Provider } from 'react-redux';
import createStore from './redux/store';

import './index.scss';

const store = createStore();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <FormContainer />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
