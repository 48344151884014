import { CookieKeys, EmbeddedCookieHandler } from '@delta-defense/delta-utils';
import { LoginCookieKeys } from '../constants';
import { EnvironmentService } from '../core/services/environmentService';

export async function deleteAuthCookies() {
  const cookieHandler = EmbeddedCookieHandler.instance();
  const environmentService = EnvironmentService.Instance();

  const domain = environmentService.domain;

  await cookieHandler.deleteCookie(CookieKeys.AccessToken, domain);
  await cookieHandler.deleteCookie(CookieKeys.SessionToken, domain);
  await cookieHandler.deleteCookie(LoginCookieKeys.SSO, domain);
  await cookieHandler.deleteCookie(LoginCookieKeys.UsccaSess, domain);
}
