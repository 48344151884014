import React from 'react';
import { Link } from 'react-router-dom';

//custom
import AuthButton from '../AuthButton/AuthButton';
import Title from '../Title/Title';

const FourOFour = () => {
  return (
    <div className="mt-5 text-center">
      <Title textPosition="auth-title--center" name="404" />
      <p style={{ textAlign: 'center', fontSize: '1.5rem' }}>Sorry, page not found.</p>
      <Link to={`/${window.location.search}`} replace>
        <AuthButton style={{ marginTop: '0' }} color="uscca-red-btn" text="Go Back" />
      </Link>
    </div>
  );
};

export default FourOFour;
